import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`TextFields are primarily used within forms but can can also be used individually
in more exceptional cases. It has a range of entry options to collect different
information.`}</p>
    <h2 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}>{`Options`}</h2>
    <h3 {...{
      "id": "text-field",
      "style": {
        "position": "relative"
      }
    }}>{`Text field`}</h3>
    <p>{`The default field type that expects short text input. If the expected input
could be more than one line, use the appropriate
`}<a parentName="p" {...{
        "href": "/components/textarea/code"
      }}>{`Text Area`}</a>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<TextField
  id="text-field"
  label="Full name"
  placeholder="e.g. John Doe"
  type="text"
/>
`}</code></pre>
    <h3 {...{
      "id": "email-field",
      "style": {
        "position": "relative"
      }
    }}>{`Email field`}</h3>
    <p>{`A valid email address is required.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<TextField
  id="email-field"
  label="Email Address"
  placeholder="e.g. yourname@mail.com"
  type="email"
  message="Enter a valid email adress."
/>
`}</code></pre>
    <h3 {...{
      "id": "number-field",
      "style": {
        "position": "relative"
      }
    }}>{`Number field`}</h3>
    <p>{`Accepts numerical input. If the user needs to enter a date, use the appropriate
`}<a parentName="p" {...{
        "href": "/components/datepicker/code"
      }}>{`Date Picker`}</a>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<TextField id="number-field" label="Age" placeholder="e.g. 37" type="number" />
`}</code></pre>
    <h3 {...{
      "id": "password-field",
      "style": {
        "position": "relative"
      }
    }}>{`Password field`}</h3>
    <p>{`Used to hide private and sensitive input.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<TextField id="password-field" label="Password" type="password" />
`}</code></pre>
    <h3 {...{
      "id": "text-field-with-action-label",
      "style": {
        "position": "relative"
      }
    }}>{`Text field with action label`}</h3>
    <p>{`An action label can be added when a secondary action is closely associated with
a TextField. For example, an action link where a user can click on to recover a
password.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<TextField
  id="action"
  label="Password"
  type="password"
  action={<LinkText href="#">Forgot password?</LinkText>}
/>
`}</code></pre>
    <h3 {...{
      "id": "text-field-with-help-message",
      "style": {
        "position": "relative"
      }
    }}>{`Text field with help message`}</h3>
    <p>{`Use a help message to provide extra instructional content that is not included
in the label or placeholder. For example, specific character requirements to
meet a valid submission.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<TextField
  id="message"
  label="Password"
  type="password"
  message="Your password must include one symbol and a number."
/>
`}</code></pre>
    <h3 {...{
      "id": "text-field-with-error-state",
      "style": {
        "position": "relative"
      }
    }}>{`Text field with error state`}</h3>
    <p>{`Used to indicate the entered input (or required fields that are empty) isn't
valid. Only affected fields should've an error state. Valid input shouldn't be
cleared.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<TextField
  id="error"
  label="Password"
  type="password"
  message="Your password must include one symbol and a number."
  error
/>
`}</code></pre>
    <h3 {...{
      "id": "text-field-with-disabled-state",
      "style": {
        "position": "relative"
      }
    }}>{`Text field with disabled state`}</h3>
    <p>{`This states disable the field and prevents the user from interacting with it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<TextField
  id="disabled"
  label="Full name"
  placeholder="Gaspare Spontini"
  disabled
/>
`}</code></pre>
    <h3 {...{
      "id": "text-field-with-an-icon",
      "style": {
        "position": "relative"
      }
    }}>{`Text field with an icon`}</h3>
    <p>{`A left side icon can be displayed to convey more meaning. Browse and discover
which icons you can use in our
`}<a parentName="p" {...{
        "href": "/foundations/iconography/"
      }}>{`Chameleon Iconography Search`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<TextField id="icon" label="Username" type="text" iconLeft={Person} />
`}</code></pre>
    <h2 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h2>
    <h3 {...{
      "id": "labels",
      "style": {
        "position": "relative"
      }
    }}>{`Labels`}</h3>
    <p>{`Every TextField should have a label. A field without a label is ambiguous and
not accessible.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<TextField
  id="labels"
  label="Full name"
  placeholder="e.g. John Doe"
  type="text"
/>
`}</code></pre>
    <p>{`To meet accessibility requirements, every TextField should have associated
label. We do support the ability to visually hide the label when the text
field's purpose is clear. Markup wise the label will remain available to screen
readers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<TextField
  id="a11y"
  label="Search"
  labelHidden
  type="text"
  placeholder="Search for a articles..."
  iconLeft={Search}
/>
`}</code></pre>
    <h3 {...{
      "id": "placeholders",
      "style": {
        "position": "relative"
      }
    }}>{`Placeholders`}</h3>
    <p>{`Placeholder text is displayed inside the field but is not always necessary. They
should be written as examples helping users to understand the required input
instead of instructions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<Box mb={5} clone>
  <TextField
    id="placeholders"
    label="Email address"
    placeholder="e.g. yourname@mail.com"
    type="text"
  />
  <TextField id="action" label="Your favourite song" type="text" />
</Box>
`}</code></pre>
    <h3 {...{
      "id": "masks",
      "style": {
        "position": "relative"
      }
    }}>{`Masks`}</h3>
    <p>{`To visually aid the user with the expected format, we recommend using an input
mask. Input masks will display a template of the expected value.`}</p>
    <h4 {...{
      "id": "bank-account",
      "style": {
        "position": "relative"
      }
    }}>{`Bank account`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<TextField
  id="bank-account"
  label="Enter your billing details"
  mask="BE99 9999 9999 9999"
  message="Belgian bank account format."
/>
`}</code></pre>
    <h4 {...{
      "id": "telephone-number",
      "style": {
        "position": "relative"
      }
    }}>{`Telephone number`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<TextField
  id="telephone-number"
  label="Telephone number"
  mask="9999/99.99.99"
  message="Belgian telephone format."
/>
`}</code></pre>
    <h3 {...{
      "id": "required-or-optional",
      "style": {
        "position": "relative"
      }
    }}>{`Required or optional?`}</h3>
    <p>{`In a single form, we recommend only marking the optional fields with an
approrpiate optional label text "(Optional)".`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<Box mb={5} clone>
  <TextField
    id="required"
    label="Email address"
    placeholder="e.g. yourname@mail.com"
    type="text"
  />
  <TextField id="action" label="Password" type="text" />
  <TextField
    id="optional"
    label="Your favourite song"
    type="text"
    optionalLabel="Optional"
  />
</Box>
`}</code></pre>
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <ul>
      <li parentName="ul">{`If the user needs to enter a date, use the
`}<a parentName="li" {...{
          "href": "/components/datepicker/code"
        }}>{`DatePicker`}</a>{` component.`}</li>
      <li parentName="ul">{`If the expected input is more than one line, us the
`}<a parentName="li" {...{
          "href": "/components/textarea/code"
        }}>{`TextArea`}</a>{` component.`}</li>
      <li parentName="ul">{`If the user has to be suggested with options, based on the input they provide,
consider using the `}<a parentName="li" {...{
          "href": "/components/autocomplete/code"
        }}>{`AutoComplete`}</a>{` component.`}</li>
      <li parentName="ul">{`If the user can select a single selection of a list of predefined options, use
a `}<a parentName="li" {...{
          "href": "/components/select/code"
        }}>{`Select`}</a>{` component.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      